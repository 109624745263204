import { isArrayEmpty, slugify } from '@/utils/helpers'
const useInput = (props: any, emit: any = undefined) => {
  const handleInput = computed({
    get() {
      return props.modelValue
    },
    set(newValue: any) {
      emit('update:modelValue', newValue)
    },
  })
  const errorMessage = computed(() => {
    if (props.v !== undefined && isArrayEmpty(props.v.$errors)) {
      return ''
    }
    return props.v?.$errors[0].$message
  })
  const errorClass = computed(() => {
    return props.required && props.v?.$error ? 'input--error' : ''
  })
  const showErrorMessage = computed(() => {
    return props.required && props.v?.$error
  })
  const inputLabel = computed(() => props.label && slugify(props.label))

  return {
    handleInput,
    errorMessage,
    errorClass,
    showErrorMessage,
    inputLabel,
  }
}
export default useInput
